const load = () => {
  const mapElem = document.getElementById('map')
  if (!mapElem) return false

  const lat = mapElem.dataset.lat
  const lon = mapElem.dataset.lon
  let titles = mapElem.dataset.titles
  if (titles) {
    titles = JSON.parse(titles)
  }

  let links = mapElem.dataset.links
  if (links) {
    links = JSON.parse(links)
  }

  const lats = (lat && [lat]) || JSON.parse(mapElem.dataset.lats)
  const lons = (lon && [lon]) || JSON.parse(mapElem.dataset.lons)


  const map = new google.maps.Map(mapElem, {
    center: { lat: 54.680664, lng: 25.283055 },
    zoom: 14,
    mapId: 'e38a743ff930a55e'
  })

  for (let i = 0; i < lats.length; i++) {
    if (!lats[i]) {
      continue
    }

    const infowindow = new google.maps.InfoWindow({ content: '<div id="content">' + titles[i] + '</div>' })

    const marker = new google.maps.Marker({
      position: { lat: lats[i], lng: lons[i] },
      map,
      title: titles[i],
    })


    marker.addListener('mouseover', () => { infowindow.open(map, marker) })
    marker.addListener('mouseout', () => { infowindow.close(map, marker) })

    if (links[i]) {
      marker.addListener('click', () => {
        let link = links[i]
        if (!link.match(/^[a-zA-Z]+:\/\//)) {
          link = 'https://' + link
        }

        window.open(link, '_blank')
      })
    }
  }
}

document.addEventListener("turbolinks:load", load)

window.initMap = load
